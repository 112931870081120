@import "@/assets/style/_index.scss";








































































































.bestow-item {
    background-color: #fff;
    + .bestow-item {
        margin-top: $margin;
    }
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding;
        border-bottom: 1px solid $color-border;
        > .code {
            color: $gray6;
        }
    }
    > .body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding;
        > .user {
            flex: 1 1 1%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            > .avatar {
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                background: $gray2 center no-repeat;
                background-size: cover;
            }
            > .info {
                margin-left: $margin;
                > .nickname {
                    font-weight: bold;
                }
                > .mobile {
                    color: $color-blue;
                }
            }
        }
        .used-time {
            color: $gray6;
            flex: 0 0 auto;
        }
    }
}
