/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */

// 清除浮动
@mixin clearfix() {
    &:before,
    &:after {
        content: '';
        display: table;
    }
    &:after {
        clear: both;
    }
}

// 创建图标
@mixin make-icon {
    display: inline-block;
    font: normal normal normal 14px/1 Fonticon;
    font-size: inherit;
    line-height: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// 单选或多行(chrome)显示
@mixin text-line($line) {
    overflow: hidden;
    display: -webkit-box;
    white-space: normal;
    word-break: break-all;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
}

@function lightness_($value) {
    @if (type-of($value) == 'color') {
        @return lightness($value);
    }
    @return 0;
}
